<mat-card class="fx-card fx-fill-height relative fx-layout-column-nowrap">
	<div
		class="fx-search-area fx-layout-row fx-content-end fx-items-end fx-gap-4 fx-padding-8"
		[formGroup]="filterForm">
		<mat-form-field subscriptSizing="dynamic">
			<mat-icon
				matIconPrefix
				fontIcon="mdi-magnify"
				fontSet="mdi"></mat-icon>
			<input
				#filter
				matInput
				placeholder="{{ 'SEARCHING' | translate }}" />
		</mat-form-field>

		<span class="fx-grow-1"></span>

		<mat-form-field subscriptSizing="dynamic" style="width: 260px">
			<mat-label>{{ 'USER' | translate }}</mat-label>
			<mat-select formControlName="user" multiple>
				@for (user of users; track user) {
					<mat-option [value]="user.username"
						>{{ user.fullName }} ({{ user.username }})
					</mat-option>
				}
			</mat-select>
		</mat-form-field>

		<div class="fx-layout-column-nowrap">
			<mat-button-toggle-group
				(change)="changeRange($event)"
				aria-label="Period"
				formControlName="period"
				name="period">
				<mat-button-toggle value="TODAY">
					{{ 'TODAY' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="YESTERDAY">
					{{ 'YESTERDAY' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="3D">
					{{ 'THREE_DAYS' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="1W">
					{{ 'ONE_WEEK' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="1M">
					{{ 'ONE_MONTH' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="3M">
					{{ 'THREE_MONTHS' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="OT">
					{{ 'ALL' | translate }}
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<mat-form-field subscriptSizing="dynamic" style="width: 208px">
			<mat-date-range-input [rangePicker]="picker">
				<input
					[placeholder]="'FROM' | translate"
					formControlName="startDate"
					matStartDate />
				<input
					[placeholder]="'TO' | translate"
					formControlName="endDate"
					matEndDate />
			</mat-date-range-input>
			<mat-datepicker-toggle
				[for]="picker"
				matSuffix></mat-datepicker-toggle>
			<mat-date-range-picker #picker></mat-date-range-picker>
		</mat-form-field>
	</div>

	<div class="fx-fill-height fx-overflow-auto">
		<mat-table
			[dataSource]="dataSource"
			[trackBy]="trackByDatetime"
			matSort
			matSortActive="datetime"
			matSortDirection="desc"
			matSortDisableClear>
			<!--Table-->
			@for (col of availableColumns; track col) {
				<ng-container
					[class.hidden]="col.hidden"
					matColumnDef="{{ col.label }}">
					<mat-header-cell
						*matHeaderCellDef
						[disabled]="!col['sortable']"
						mat-sort-header>
						{{ col.header | translate }}
					</mat-header-cell>

					@if (col.unit === 'date') {
						<mat-cell
							*matCellDef="let row"
							style="font-weight: bold">
							{{
								row[col.value] | date: 'dd/MM/yyyy HH:mm'
							}}</mat-cell
						>
					}

					@if (col.unit !== 'date') {
						<mat-cell *matCellDef="let row">
							{{ row[col.value] }}</mat-cell
						>
					}
				</ng-container>
			}

			<!--header-->
			<mat-header-row
				*matHeaderRowDef="
					columnsToDisplay;
					sticky: true
				"></mat-header-row>
			<mat-row
				[@rowsAnimation]=""
				*matRowDef="let row; columns: columnsToDisplay"></mat-row>
		</mat-table>

		<div
			[style.display]="dataSource.data.length === 0 ? '' : 'none'"
			class="fx-table-empty">
			@if (isLoadingResults) {
				<div>
					<mat-spinner
						[diameter]="50"
						[strokeWidth]="3"
						color="warn"></mat-spinner>
				</div>
			} @else {
				{{ 'NOITEMSFOUND' | translate }}
			}
		</div>
	</div>
	<mat-paginator
		[length]="resultsLength"
		[pageIndex]="0"
		[pageSizeOptions]="[5, 10, 25, 50, 100]"
		[pageSize]="25"
		[showFirstLastButtons]="true">
	</mat-paginator>
</mat-card>
